<template>
  <div class="page-container relative">
    <div class="absolute -top-32 -left-32">
      <img
        src="https://playda.s3.ap-south-1.amazonaws.com/diy/log_in_page_blob_1.webp"
        alt="Playda DIY Blob"
        width="400"
        height="340"
      />
    </div>
    <div class="grid">
      <div class="register flex gap-10">
        <div class="w-3/5 flex items-center pl-20">
          <div class="w-11/12">
            <div class="flex justify-center">
              <img
                src="https://playda.s3.ap-south-1.amazonaws.com/diy/illustration.webp"
                alt="Playda DIY Login"
                class="w-full"
              />
            </div>
            <div class="my-4 text-white">
              <div class="mb-2 font-InterSemiBold text-2xl">
                <strong
                  >Get Ready to Game! Create Your Own Game Jam <br />in 4 Easy
                  Steps:</strong
                >
              </div>
              <ul class="mb-4">
                <li class="my-1">
                  <span class="text-nColorYellow"
                    ><strong>1. Customize</strong></span
                  >
                  — Add your name and logo to make it yours.
                </li>
                <li class="my-1">
                  <span class="text-nColorYellow"
                    ><strong>2. Choose Your Games</strong></span
                  >
                  — Pick your favorites from our catalog.
                </li>
                <li class="my-1">
                  <span class="text-nColorYellow"
                    ><strong>3. Generate & Share</strong></span
                  >
                  — Get your URL and QR code to spread the word.
                </li>
                <li class="my-1">
                  <span class="text-nColorYellow"
                    ><strong>4. Launch the Fun</strong></span
                  >
                  — Kick off the Game Jam when everyone's in!
                </li>
              </ul>
              <div class="my-2">
                <strong
                  >It's quick, easy, and guaranteed fun! Let's get
                  jamming!</strong
                >
              </div>
            </div>
          </div>
        </div>
        <div class="w-2/5" v-if="emailSection">
          <div class="flex gap-4 font-InterSemiBold text-white p-8 float-right">
            <a :href="marketingURL">About Playda</a>
            <a :href="contactURL">Contact us</a>
          </div>
          <div class="mt-20">
            <div>
              <img
                src="https://playda.s3.ap-south-1.amazonaws.com/playda-logos/playda-logo-dark.webp"
                alt="Playda Logo"
                width="200"
                height="71"
              />
            </div>
            <div class="font-InterSemiBold text-2xl mt-4 text-white">
              Welcome to the gaming extravaganza!
            </div>
            <div class="mt-6 text-white">
              <div class="grid gap-2">
                <div class="w-4/5">
                  <p class="font-InterSemiBold uppercase">Full name</p>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    v-model="name"
                    class="textInput"
                    placeholder="What do we call you?"
                  />
                </div>
                <div class="w-4/5">
                  <p class="font-InterSemiBold uppercase">Email ID</p>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    v-model="email"
                    class="textInput"
                    placeholder="Preferred Registration Email"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="mt-6 grid items-center">
            <div>
              <button
                @click.prevent="validateEmail()"
                :class="[
                  'bg-nColorYellow rounded-3xl w-10/12 h-14 text-nColorButtonText text-center uppercase text-3xl font-ZuumeSemiBold tracking-wider focus:outline-none',
                  { disabled: isValidateLoading },
                ]"
                :disabled="isValidateLoading"
              >
                <span v-if="isValidateLoading" class="flex justify-center">
                  <svg
                    width="60"
                    height="15"
                    viewBox="0 0 120 30"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#fff"
                  >
                    <circle cx="15" cy="15" r="15">
                      <animate
                        attributeName="r"
                        from="15"
                        to="15"
                        begin="0s"
                        dur="0.8s"
                        values="15;9;15"
                        calcMode="linear"
                        repeatCount="indefinite"
                      />
                      <animate
                        attributeName="fill-opacity"
                        from="1"
                        to="1"
                        begin="0s"
                        dur="0.8s"
                        values="1;.5;1"
                        calcMode="linear"
                        repeatCount="indefinite"
                      />
                    </circle>
                    <circle cx="60" cy="15" r="9" fill-opacity="0.3">
                      <animate
                        attributeName="r"
                        from="9"
                        to="9"
                        begin="0s"
                        dur="0.8s"
                        values="9;15;9"
                        calcMode="linear"
                        repeatCount="indefinite"
                      />
                      <animate
                        attributeName="fill-opacity"
                        from="0.5"
                        to="0.5"
                        begin="0s"
                        dur="0.8s"
                        values=".5;1;.5"
                        calcMode="linear"
                        repeatCount="indefinite"
                      />
                    </circle>
                    <circle cx="105" cy="15" r="15">
                      <animate
                        attributeName="r"
                        from="15"
                        to="15"
                        begin="0s"
                        dur="0.8s"
                        values="15;9;15"
                        calcMode="linear"
                        repeatCount="indefinite"
                      />
                      <animate
                        attributeName="fill-opacity"
                        from="1"
                        to="1"
                        begin="0s"
                        dur="0.8s"
                        values="1;.5;1"
                        calcMode="linear"
                        repeatCount="indefinite"
                      />
                    </circle>
                  </svg>
                </span>
                <span v-else> GET OTP </span>
              </button>
            </div>
            <div class="w-full pl-4">
              <div class="text-sm inline-block mt-2 h-4">
                <div v-if="errorMsg" class="error text-red-600 rounded">
                  <p class="text-sm">{{ errorMsg }}</p>
                </div>
                <div v-if="successMsg" class="success text-green-700">
                  <p class="text-sm">
                    {{ successMsg }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="w-10/12 flex gap-2 mt-4 items-center">
              <hr class="w-1/2 border-nColorLightGray" />
              <div class="text-nColorLightGray">OR</div>
              <hr class="w-1/2 border-nColorLightGray" />
            </div>
            <div class="w-10/12 flex justify-center mt-4">
              <div class="h-20">
                <GoogleLogin
                  :callback="signupUsingGoogle"
                  :buttonConfig="{
                    size: 'large',
                    shape: 'pill',
                    text: 'sign up using',
                    width: '400',
                  }"
                  prompt
                />
              </div>
            </div>
          </div>
          <div class="mt-6">
            <div
              @click.prevent="goToLogin()"
              class="font-InterSemiBold underline text-white"
            >
              Already have an account? Log in
            </div>
          </div>
        </div>
        <div class="w-2/5" v-if="otpSection">
          <div class="flex gap-4 font-InterSemiBold text-white float-right p-8">
            <a :href="marketingURL">About Playda</a>
            <a :href="contactURL">Contact us</a>
          </div>
          <div class="w-3/4">
            <div class="font-InterRegular text-xs mt-20">
              <div>
                <img
                  src="https://playda.s3.ap-south-1.amazonaws.com/playda-logos/playda-logo-dark.webp"
                  alt="Playda Logo"
                  width="200"
                  height="71"
                />
              </div>
              <div class="font-InterSemiBold text-2xl mt-4 text-white">
                Welcome to the gaming extravaganza!
              </div>
            </div>
            <div class="flex gap-8 mt-4">
              <input
                v-for="(i, index) in 4"
                :key="i"
                @keydown.space.prevent=""
                maxlength="1"
                class="otpText"
                type="number"
                @input="handleInput(i, $event)"
                @keydown.delete="movePrev(i)"
                :ref="'otp' + i"
                :autofocus="index === 0"
              />
            </div>
            <div class="grid gap-4">
              <div class="mt-6">
                <div>
                  <span class="font-InterRegular text-sm text-nColorLightGray"
                    >Enter the 4-digit OTP that we have sent to your email</span
                  >
                  <span class="font-InterMedium text-sm ml-1 text-nColorOrange"
                    ><strong>{{ email }}</strong></span
                  >
                </div>
              </div>
              <div class="flex justify-between items-center">
                <div class="text-nColorOrange justify-center">
                  <button
                    @click="enableEmail"
                    class="underline font-InterSemiBold text-sm"
                  >
                    Change email
                  </button>
                </div>
                <div class="font-ManropeMedium text-sm">
                  <button :disabled="resendDisabled" @click="resendOTP">
                    <span
                      :class="{
                        'text-gray-400': resendDisabled,
                        'text-nColorOrange underline font-bold':
                          !resendDisabled,
                      }"
                    >
                      Resend OTP
                    </span>
                    <span v-if="resendDisabled" class="text-gray-400"
                      >({{ countdown }})</span
                    >
                  </button>
                </div>
              </div>
            </div>
            <div class="mt-6">
              <button
                @click.prevent="validateOTP()"
                :class="[
                  'bg-nColorYellow rounded-3xl w-full h-14 text-nColorButtonText text-center uppercase text-3xl font-ZuumeSemiBold tracking-wider focus:outline-none',
                  { disabled: isRegisterLoading },
                ]"
                :disabled="isRegisterLoading"
              >
                <span v-if="isRegisterLoading" class="flex justify-center">
                  <svg
                    width="60"
                    height="15"
                    viewBox="0 0 120 30"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#000"
                  >
                    <circle cx="15" cy="15" r="15">
                      <animate
                        attributeName="r"
                        from="15"
                        to="15"
                        begin="0s"
                        dur="0.8s"
                        values="15;9;15"
                        calcMode="linear"
                        repeatCount="indefinite"
                      />
                      <animate
                        attributeName="fill-opacity"
                        from="1"
                        to="1"
                        begin="0s"
                        dur="0.8s"
                        values="1;.5;1"
                        calcMode="linear"
                        repeatCount="indefinite"
                      />
                    </circle>
                    <circle cx="60" cy="15" r="9" fill-opacity="0.3">
                      <animate
                        attributeName="r"
                        from="9"
                        to="9"
                        begin="0s"
                        dur="0.8s"
                        values="9;15;9"
                        calcMode="linear"
                        repeatCount="indefinite"
                      />
                      <animate
                        attributeName="fill-opacity"
                        from="0.5"
                        to="0.5"
                        begin="0s"
                        dur="0.8s"
                        values=".5;1;.5"
                        calcMode="linear"
                        repeatCount="indefinite"
                      />
                    </circle>
                    <circle cx="105" cy="15" r="15">
                      <animate
                        attributeName="r"
                        from="15"
                        to="15"
                        begin="0s"
                        dur="0.8s"
                        values="15;9;15"
                        calcMode="linear"
                        repeatCount="indefinite"
                      />
                      <animate
                        attributeName="fill-opacity"
                        from="1"
                        to="1"
                        begin="0s"
                        dur="0.8s"
                        values="1;.5;1"
                        calcMode="linear"
                        repeatCount="indefinite"
                      />
                    </circle>
                  </svg>
                </span>
                <span v-else> Register </span>
              </button>
              <div class="w-full">
                <div class="text-sm inline-block mt-2 h-4">
                  <div v-if="errorMsgSubmit" class="error text-red-600 rounded">
                    <p class="text-sm">{{ errorMsgSubmit }}</p>
                  </div>
                  <div v-if="successMsgSubmit" class="success text-green-700">
                    <p class="text-sm">
                      {{ successMsgSubmit }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-6">
              <div
                @click.prevent="goToLogin()"
                class="font-InterSemiBold underline text-white"
              >
                Already have an account? Log in
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-10">
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import { decodeCredential } from "vue3-google-login";
import { useToast } from "vue-toastification";
import TokenService from "@/common/token.service";
import Footer from "@/components/Footer/Desktop.vue";

const toast = useToast();

export default {
  name: "Register",
  components: { Footer },
  data() {
    return {
      name: null,
      email: null,
      isValidateLoading: false,
      isRegisterLoading: false,
      emailSection: true,
      otpSection: false,
      resendDisabled: false,
      countdown: 60,
      otp: "",
      errorMsg: "",
      successMsg: "",
      errorMsgSubmit: "",
      successMsgSubmit: "",
      marketingURL: process.env.VUE_APP_MARKETING_URL,
      contactURL: process.env.VUE_APP_CONTACT_URL,
      utm: {
        src: this.$route.query.utm_source,
        medium: this.$route.query.utm_medium,
        campaign: this.$route.query.utm_campaign,
        content: this.$route.query.utm_content,
      },
    };
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {
    signupUsingGoogle(response) {
      let userData = decodeCredential(response.credential);
      ApiService.post(apiResource.continueWithGoogle, {
        name: userData.name,
        email: userData.email,
        picture: userData.picture,
        utm: this.utm,
      }).then((data) => {
        this.isLoginLoading = false;
        this.$store.dispatch("validateLoginOTP", data);
        this.goToDashboard();
      });
    },
    validateEmail() {
      console.log(this.utm);
      this.isValidateLoading = true;
      this.errorMsg = "";
      if (this.name && this.email) {
        ApiService.post(apiResource.validateCustomer, {
          name: this.name,
          email: this.email,
          utm: this.utm,
        })
          .then(() => {
            this.isValidateLoading = false;
            this.emailSection = false;
            this.otpSection = true;
            this.startResendCounter();
          })
          .catch((error) => {
            this.isValidateLoading = false;
            this.successMsg = null;
            if (typeof error.response.data.msg === "object") {
              this.errorMsg =
                error.response.data.msg[
                  Object.keys(error.response.data.msg)[0]
                ];
            } else {
              this.errorMsg = error.response.data.msg;
            }
          });
      } else {
        this.isValidateLoading = false;
        this.errorMsg = "Please fill all the fields";
      }
    },
    resendOTP() {
      ApiService.post(apiResource.resendOTP, {
        email: this.email,
      })
        .then(() => {
          this.startResendCounter();
          toast.success("OTP resent successfully!", { timeout: 2000 });
        })
        .catch(() => {});
    },
    validateOTP() {
      this.isRegisterLoading = true;
      this.errorMsgSubmit = "";
      console.log(this.otp);
      if (this.otp) {
        ApiService.post(apiResource.validateOTP, {
          email: this.email,
          otp: this.otp,
        })
          .then((data) => {
            this.isRegisterLoading = false;
            this.$store.dispatch("validateOTP", data);
            this.goToDashboard();
          })
          .catch((error) => {
            this.isRegisterLoading = false;
            this.successMsgSubmit = null;
            if (typeof error.response.data.msg === "object") {
              this.errorMsgSubmit =
                error.response.data.msg[
                  Object.keys(error.response.data.msg)[0]
                ];
            } else {
              this.errorMsgSubmit = error.response.data.msg;
            }
          });
      } else {
        this.isRegisterLoading = false;
        this.errorMsgSubmit = "Please enter the OTP";
      }
    },
    goToDashboard() {
      var pricingPlan = TokenService.getToken("pricing_plan");
      if (pricingPlan) {
        this.$router.replace({
          name: "Payment",
        });
      } else {
        this.$router.replace({
          name: "Dashboard",
        });
      }
    },
    handleInput(index, event) {
      const inputValue = event.target.value;

      if (inputValue.length > 1) {
        event.target.value = inputValue.charAt(0);
      }

      if (inputValue.length === 1 && index < 4) {
        const nextInputRef = `otp${index + 1}`;
        const nextInput = this.$refs[nextInputRef][0];
        nextInput.focus();
      }

      if (inputValue.length === 0 && index > 1) {
        const prevInputRef = `otp${index - 1}`;
        const prevInput = this.$refs[prevInputRef][0];
        prevInput.value = ""; // Clear previous input value
        prevInput.focus();
      }

      this.updateOTPValue();
    },
    movePrev(index) {
      if (index > 1) {
        const currentInputRef = `otp${index}`;
        const currentInput = this.$refs[currentInputRef][0];
        const prevInputRef = `otp${index - 1}`;
        const prevInput = this.$refs[prevInputRef][0];

        currentInput.value = ""; // Clear current input value
        prevInput.value = ""; // Clear previous input value
        prevInput.focus();
      }
    },
    updateOTPValue() {
      const otpInputValues = Array.from(
        document.querySelectorAll(".otpText")
      ).map((input) => input.value);

      this.otp = otpInputValues.join("");
    },
    startResendCounter() {
      this.resendDisabled = true;
      this.countdown = 60;

      const timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          this.resendDisabled = false;
          clearInterval(timer);
        }
      }, 1000);
    },
    enableEmail() {
      window.location.reload();
    },
    goToLogin() {
      if (this.utm.src) {
        this.$router.push({
          name: "Login",
          query: {
            utm_source: this.utm.src,
            utm_medium: this.utm.medium,
            utm_campaign: this.utm.campaign,
            utm_content: this.utm.content,
          },
        });
      } else {
        this.$router.push({
          name: "Login",
        });
      }
    },
  },
};
</script>

<style scoped lang="postcss">
.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
}
.textInput {
  @apply w-full text-white pt-2 pb-3 focus:outline-none bg-nColorTextInput rounded-xl px-4;
}
.radio-group {
  display: flex;
}

.radio-selector {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}

/* Style the radio input to be hidden */
input[type="radio"] {
  display: none;
}
.selected {
  @apply border-nColorOrange text-nColorOrange;
}
.otpText {
  @apply bg-nColorTextInput rounded-xl font-InterBold text-nColorYellow text-4xl focus:outline-none focus:bg-nColorYellow focus:text-nColorButtonText text-center w-20 h-20 uppercase py-1 px-1;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
