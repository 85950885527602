<template>
  <div class="bg-black text-nColorFooterText font-ReadexProMedium py-10">
    <div class="grid justify-center">
      <div class="flex justify-center">
        <a :href="baseURL">
          <img
            src="https://playda.s3.ap-south-1.amazonaws.com/playda-logos/playda-logo-dark.webp"
            alt="Playda Logo"
            width="100"
            height="35"
          />
        </a>
      </div>
      <div class="mt-6">
        <ul class="grid justify-center gap-2">
          <li><a :href="baseURL + '/how-it-works'">How it works</a></li>
          <li>
            <a :href="baseURL + '/gaming-platform-for-customer-engagement'"
              >For Customers</a
            >
          </li>
          <li>
            <a :href="baseURL + '/gaming-platform-for-employee-engagement'"
              >For Employees</a
            >
          </li>
        </ul>
      </div>
      <div>
        <ul class="grid justify-center gap-2 mt-2">
          <li><a :href="baseURL + '/integration'">Integration</a></li>
          <li>
            <a :href="baseURL + '/contact-for-corporate-gaming'">Contact</a>
          </li>
          <li><a href="https://blog.playda.io">Blog</a></li>
        </ul>
      </div>
      <div class="mt-6">
        <ul class="flex gap-10">
          <li>
            <a
              href="https://www.facebook.com/profile.php?id=100094728386277"
              target="_blank"
              ><i class="my-icon icon-facebook"></i
            ></a>
          </li>
          <li>
            <a href="https://www.youtube.com/@texperia" target="_blank"
              ><i class="my-icon icon-youtube"></i
            ></a>
          </li>
          <li>
            <a href="https://www.instagram.com/letsplayda/" target="_blank"
              ><i class="my-icon icon-instagram"></i
            ></a>
          </li>
          <li>
            <a href="https://www.linkedin.com/showcase/playda/" target="_blank"
              ><i class="my-icon icon-linkedin-in"></i
            ></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="border-t border-nColorBorder my-6"></div>
    <div class="grid justify-center">
      <div class="text-center">
        ©Texperia Techsolutions Pvt Ltd 2024 | All Rights Reserved
      </div>
      <div>
        <ul class="flex justify-center mt-6 gap-6">
          <li><a :href="baseURL + '/privacy-policy'">Privacy Policy</a></li>
          <li>
            <a :href="baseURL + '/terms-and-conditions'">Terms & Conditions</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  components: {},
  data() {
    return {
      baseURL: process.env.VUE_APP_MARKETING_URL,
    };
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {},
};
</script>

<style scoped lang="postcss"></style>
